import { HTTP } from '../../utilities/http-common'
import { ServiceUrls } from '../../utilities/serviceUrls'
import { tenantSpecificURL } from '@/utilities/utilities'
const getDefaultState = () => {
  return {
    emailTemplates: [],
    emailSettings: [],
    linkAccounts: [],
    securityMethods: []
  }
}
// initial state
const state = getDefaultState()

// getters
const getters = {}
// actions
const actions = {
  getEmailTemplates({ commit }) {
    return HTTP.get(`${ServiceUrls.getEmailTemplates}`)
      .then(res => {
        commit('setEmailTemplates', res.data)
        return res
      })
      .catch(error => {
        commit('setEmailTemplates', [])
        return Promise.reject(error)
      })
  },
  getEmailSettings({ commit, rootState }, templateId) {
    const tenantId = rootState.common.selectedTenantIdByRole
    const url = tenantId ? `${ServiceUrls.getEmailSettings}/${templateId}/${tenantId}` : `${ServiceUrls.getEmailSettings}/${templateId}`
    return HTTP.get(url)
      .then(res => {
        commit('setEmailSettings', res.data)
        return res
      })
      .catch(error => {
        commit('setEmailSettings', [])
        return Promise.reject(error)
      })
  },

  updateEmailSetting({ commit }, postObj) {
    return HTTP.post(`${ServiceUrls.updateEmailSetting}`, postObj)
      .then(res => {
        return res
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  getLinkAccounts({ commit, rootState }, tenantId) {
    tenantId = tenantId || rootState.common.selectedTenantIdByRole
    const url = tenantId ? `${ServiceUrls.getLinkAccounts}/${tenantId}` : ServiceUrls.getLinkAccounts
    return HTTP.get(url)
      .then((res) => {
        commit('setLinkAccounts', res.data)
        return res
      })
      .catch((error) => {
        commit('setLinkAccounts', [])
        return Promise.reject(error)
      })
  },
  getLinkAccountDetails({ commit }, postObj) {
    const url = postObj.authenticationType === 'external-link-account' ? ServiceUrls.getExternalLinkAccountDetails : ServiceUrls.getLinkAccountDetails
    return HTTP.get(`${url}/${postObj.linkAccountId}`)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  addLinkAccount({ commit }, postObj) {
    const url = postObj.authenticationType === 'external-link-account' ? ServiceUrls.addExternalLinkAccount : ServiceUrls.addLinkAccount
    return HTTP.post(url, postObj.data)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  updateLinkAccount({ commit }, postObj) {
    const url = postObj.authenticationType === 'external-link-account' ? ServiceUrls.updateExternalLinkAccount : ServiceUrls.updateLinkAccount
    return HTTP.post(url, postObj.data)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  deleteLinkAccount({ commit }, postObj) {
    const url = postObj.authenticationType === 'external-link-account' ? ServiceUrls.deleteExternalLinkAccount : ServiceUrls.deleteLinkAccount
    return HTTP.post(`${url}/${postObj.linkAccountId}`)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  linkAccountTestApi({ commit }, postObj) {
    return HTTP.post(tenantSpecificURL(ServiceUrls.linkAccountTestApi), postObj)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  unlinkAccountTestApi({ commit }, postObj) {
    return HTTP.post(tenantSpecificURL(ServiceUrls.unlinkAccountTestApi), postObj)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }
}
// mutations
const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setEmailTemplates(state, payload) {
    state.emailTemplates = payload
  },
  setEmailSettings(state, payload) {
    state.emailSettings = payload
  },
  setLinkAccounts(state, payload) {
    state.linkAccounts = payload
  },
  setSecurityMethods(state, payload) {
    state.securityMethods = [...payload]
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
