export const ServiceUrls = {
  getWidgets: 'api/v1/widgets/get-all-widgets-details',
  updateReorderWidgets: 'api/v1/widgets/update-widgets-reorder',
  getWidgetDetails: 'api/v1/widgetcommon/get-widget-details',
  addWidgetDetails: 'api/v1/widgetcommon/add-widget-details',
  updateWidgetDetails: 'api/v1/widgetcommon/update-widget-details',
  deleteWidget: 'api/v1/widgetcommon/delete-widget',
  activateOrDeactivateWidget: 'api/v1/widgets/activate-or-deactivate-widget',
  getAdminTenants: 'api/v1/tenantsetting/get-admin-tenants',
  getCities: 'api/v1/tenantsetting/get-all-tenant-details',
  getSocialMediaConnectNames: 'api/v1/tenantsetting/get-social-media-connects',
  getTenantSettings: 'api/v1/tenantsetting/get-tenant-settings',
  updateTenantSettings: 'api/v1/tenantsetting/update-tenant-settings',
  getCalendarTypes: 'api/v1/calendar/get-event-calendars',
  getCalendarCategories: 'api/v1/calendar/get-calendar-categories',
  getEmailTemplates: 'api/v1/emailsettings/get-email-templates',
  getEmailSettings: 'api/v1/emailsettings',
  updateEmailSetting: 'api/v1/emailsettings/update-email-setting',
  getApplicableWidgets: 'api/v1/applicablewidgets/get-applicable-widgets',
  updateApplicableWidgets: 'api/v1/applicableWidgets/update-applicable-widgets',
  getNewsCategories: 'api/v1/newswidget/get-news-categories',
  getLinkAccounts: 'api/v1/linkaccount/get-link-accounts',
  getLinkAccountDetails: 'api/v1/linkaccount/get-link-account-details',
  addLinkAccount: 'api/v1/linkaccount/add-link-account',
  updateLinkAccount: 'api/v1/linkaccount/update-link-account',
  deleteLinkAccount: 'api/v1/linkaccount/delete-link-account',
  getExternalLinkAccountDetails: 'api/v1/externallinkaccount/get-external-link-account-details',
  addExternalLinkAccount: 'api/v1/externallinkaccount/add-external-link-account',
  updateExternalLinkAccount: 'api/v1/externallinkaccount/update-external-link-account',
  deleteExternalLinkAccount: 'api/v1/externallinkaccount/delete-external-link-account',
  getAddressConfigurations: 'api/v1/addresssetup/get-address-configurations',
  updateAddressConfigurations: 'api/v1/addresssetup/update-address-configurations',
  getBlobIconList: 'api/v1/blob/get-blob-icon-list',
  getStreetNames: 'api/v1/testapi/get-street-names',
  getStreetNumbers: 'api/v1/testapi/get-street-number',
  getUnitNumbers: 'api/v1/testapi/get-unit-number',
  executeLocationTestAPI: 'api/v1/testapi/execute-loc-test-api',
  executeIntegrationTestAPI: 'api/v1/testapi/execute-intgn-test-api',
  getAPICommunicationConfiguration: 'api/v1/apicommunication/get-apicommunication-configuration',
  getLinkedAccountUsers: 'api/v1/testapi/get-linked-users',
  getLinkedAccountUserAddresses: 'api/v1/testapi/get-linked-users-addresses',
  getMywasteCalendars: 'api/v1/mywaste/get-mywaste-calendars',
  getMyWastPlatform: 'api/v1/mywaste/get-platform',
  isActiveWidgetNotExist: 'api/v1/applicableWidgets/is-active-widget-not-exist',
  linkAccountTestApi: 'api/v1/testapi/link-account-test-api',
  unlinkAccountTestApi: 'api/v1/testapi/unlink-account-test-api',
  getContentEmailTemplate: 'api/v1/widgetcommon/get-content-email-template',
  getSecurityMethods: 'api/v1/apiSecurity/get-all-api-security-methods',
  deleteSecurityMethod: 'api/v1/apiSecurity/delete-api-security-method',
  getAssociatedSecurityMethodNames: 'api/v1/apiSecurity/get-associated-security-method-names',
  addSecurityMethod: 'api/v1/apiSecurity/add-api-security-method',
  updateSecurityMethod: 'api/v1/apiSecurity/update-api-security-method',
  getSecurityMethod: 'api/v1/apiSecurity/get-api-security-method',
  getDefaultSecurityMethodId: 'api/v1/apiSecurity/get-default-set-method-id',
  getTimezones: '/api/v1/timeZone/get-time-zones',
}
