import { HTTP } from '../../utilities/http-common'
import { ServiceUrls } from '../../utilities/serviceUrls'
const getDefaultState = () => {
  return {
    cities: [],
    socialMediaConnectNames: [],
    timezones: [],
  }
}
// initial state
const state = getDefaultState()

// getters
const getters = {

}
// actions
const actions = {
  getSocialMediaConnectNames({ dispatch, commit, state }) {
    return HTTP.get(`${ServiceUrls.getSocialMediaConnectNames}`)
      .then((res) => {
        commit('setSocialMediaConnectNames', res.data)
        return res
      })
      .catch((error) => {
        commit('setSocialMediaConnectNames', [])
        return Promise.reject(error)
      })
  },
  getCities({ dispatch, commit, state }) {
    return HTTP.get(`${ServiceUrls.getCities}`)
      .then((res) => {
        commit('setCities', res.data)
        return res
      })
      .catch((error) => {
        commit('setCities', [])
        return Promise.reject(error)
      })
  },
  getTenantSettings({ rootState }, tenantID) {
    tenantID = tenantID || rootState.common.selectedTenantIdByRole
    const url = tenantID ? `${ServiceUrls.getTenantSettings}/${tenantID}` : ServiceUrls.getTenantSettings
    return HTTP.get(url)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  updateTenantSettings({ commit }, postObj) {
    const formData = new FormData()
    for (const key in postObj) {
      if (Object.hasOwn(postObj, key)) {
        formData.append(key, postObj[key] != null ? postObj[key] : '')
      }
    }
    return HTTP.post(`${ServiceUrls.updateTenantSettings}`, formData)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  getApplicableWidgets({ commit }, tenantID) {
    return HTTP.get(`${ServiceUrls.getApplicableWidgets}/${tenantID}`)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  isActiveWidgetNotExist({ commit }, payload) {
    return HTTP.get(`${ServiceUrls.isActiveWidgetNotExist}/${payload.widgetCategoryId}/${payload.tenantID}`)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  updateApplicableWidgets({ commit }, postObj) {
    return HTTP.post(`${ServiceUrls.updateApplicableWidgets}`, postObj)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  getTenantServiceIdList({ commit }) {
    return HTTP.get(process.env.VUE_APP_GET_TENANTS, {
      headers: {
        'Ocp-Apim-Subscription-Key': process.env.VUE_APP_APIM_KEY,
      },
    })
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  getMywasteCalendars({ commit }, postObj) {
    return HTTP.post(ServiceUrls.getMywasteCalendars, postObj)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  getTimezones({ commit }) {
    return HTTP.get(`${ServiceUrls.getTimezones}`)
      .then((res) => {
        commit('setTimezones', res.data)
        return res
      })
      .catch((error) => {
        commit('setTimezones', [])
        return Promise.reject(error)
      })
  },
}
// mutations
const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setSocialMediaConnectNames(state, payload) {
    state.socialMediaConnectNames = payload
  },
  setCities(state, payload) {
    state.cities = payload
  },
  setTimezones(state, payload) {
    state.timezones = payload
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
